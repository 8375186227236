import Button from "@demystdata/ui/button";
import { useEffect, useState } from "react";
import { linksConstants } from "../../../constants/linksConstants";

export const CookieMessage = () => {
  const [hide, setHide] = useState(true);

  const getCookies = () => {
    return document?.cookie
      ?.split(";")
      .map(v => v.split("="))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim());
        return acc;
      }, {});
  };
  const handleAceptCookies = () => {
    document.cookie = `_manta_cookies=all;expires=${new Date(
      Date.now() + 24 * 3600000 /* ms in hours */ * 365,
    )}`;
    setHide(true);
  };

  useEffect(() => {
    if (
      getCookies()["_manta_cookies"] ||
      hide ||
      window.location.pathname === linksConstants.MANAGE_COOKIE
    )
      setHide(
        !!getCookies()["_manta_cookies"] &&
          window.location.pathname !== linksConstants.MANAGE_COOKIE,
      );
  }, []);

  if (hide) return null;

  return (
    <div className="cookies-message">
      <div>
        Demyst may serve cookies to analyze traffic to this site. Information about your use of this
        site is shared with Demyst for that purpose.
        <div>
          <Button onClick={handleAceptCookies}>Ok, Got it</Button>
          <Button onClick={() => window.open(linksConstants.COOKIE_POLICY)}>Cookie Policy</Button>
          <Button onClick={() => window.open(linksConstants.MANAGE_COOKIE)}>Manage Cookies</Button>
        </div>
      </div>
    </div>
  );
};
